<template>
  <div class="todayorders_main">
    <div class="table-header todayorders">
      <span>订单编号</span>
      <span>商品</span>
      <span>订单总价</span>
    </div>
    <div class="table-main" ref="tabmain">
      <div v-if="list" class="table-item todayorders" v-for="v in list">
        <span>{{ v.sn }}</span>
        <span>
            <p v-for="good in v.goods">{{ good.name }} ( ￥{{ good.price }} * {{ good.num }} )</p>
          </span>
        <span>￥{{v.amount}}</span>
      </div>
      <p class="tip" v-if="!list">{{msg}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "todayorders",
  data(){
    return {
      list:'',
      allList:'',
      msg:'加载中...'
    }
  },
  created() {
    if(this.$route.query.name=='youzan'){
      this.getYouzan()
    }else if(this.$route.query.name=='todayorders'){
      this.getTodayorders()
    }
    this.$nextTick(() => {
      const el = this.$refs.tabmain;
      const offsetHeight = el.offsetHeight;
      el.onscroll = () => {
        const scrollTop = el.scrollTop;
        const scrollHeight = el.scrollHeight;
        if ((offsetHeight + scrollTop) - scrollHeight >= -1 && this.allList.length) {
          this.list = this.list.concat(this.allList.splice(0,10))
        }
      };
    });
  },
  methods:{
    getTodayorders() {
      this.$axios.post("screen/api/get_todayorders", {}).then((res) => {
        this.allList = res.data.data
        this.allList.forEach(v=>{
          v.goods=JSON.parse(v.goods)
        })
        this.list =  this.allList.splice(0,20)
        if(!this.list){
          this.msg = '暂无数据'
        }
      }).catch(err=>{
        console.log(err)
      });
    },
    getYouzan(){
      this.$axios.post("screen/api/get_youzan", {
        module:'money'
      }).then((res) => {
        console.log(res.data.data)
        this.allList = res.data.data
        this.allList.forEach(v=>{
          v.goods=JSON.parse(v.goods)
        })
        this.list =  this.allList.splice(0,20)
        if(!this.list){
          this.msg = '暂无数据'
        }
      }).catch(err=>{
        console.log(err)
      });
    }
  },
}
</script>

<style scoped>
.todayorders_main{
  height: 80%;
}
.table-header{
  display: flex;
  width: 92%;
  margin: 40px auto 0;

  font-size: 18px;
  font-weight: 600;
  color: #FFFFFF;
  padding: 0 3%;
  box-sizing: border-box;
}
.todayorders span:nth-child(1){
  flex: 1.3;
}
.todayorders span:nth-child(2){
  flex: 4.5;
  padding: 0 30px 0 0;
}
.todayorders span:nth-child(2) p{
  margin: 10px 0 0;
}
.todayorders span:nth-child(2) p:nth-child(1){
  margin-top: 0;
}
.todayorders span:nth-child(3){
  flex: 0.5;
}
.table-main{
  height: 100%;
  width: 92%;
  margin: 29px auto 0;
  overflow-y: auto;
  position: relative;
  z-index: 100000;
}
.table-main .tip{
  color: #909399;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.table-main::-webkit-scrollbar{
  display: none;
}
.table-item{
  padding: 1% 3%;
  box-sizing: border-box;
  margin-top: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  background: rgba(47, 127, 175, 0.4);

  font-size: 17px;
  color: #FFFFFF;
  border-radius: 5px;
}
</style>
